

















































































import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import CardProchaineActivite from "@/components/card/cardProchaineActivite.vue";
import CardCalendrier from "@/components/card/cardCalendrier.vue";
import CardFc from "@/components/card/cardFC.vue";
import Alert, { TypeAlert } from "@/components/alert/alert.vue";
import panelActivite from "@/components/panel/panelActivite.vue";
import optionActivite from "@/components/option/optionActivite.vue";
import Modal from "@/components/modal/modal.vue";
import Cta from "@/components/buttons/Button.vue";
import Tag from "@/components/tags/Tag.vue";
import { activites, data, login, timeline } from "@/store";
import {
  Activite,
  ActiviteRecensementState,
  ElementKey,
  ProchaineActivite,
  User,
} from "@/models";
import TimelineService from "@/services/timeline.service";
import { found, select, isSameDay } from "@/helpers";
import dayjs from "dayjs";

@Component({
  methods: {
    found,
    select,
    isSameDay,
  },
  components: {
    Sidebar,
    CardProchaineActivite,
    Cta,
    CardCalendrier,
    CardFc,
    Alert,
    panelActivite,
    optionActivite,
    Modal,
    Tag,
  },
})
export default class Recyclage extends Vue {
  private dataReady = false;
  private filterTypes: ElementKey[] | null = null;

  async mounted(): Promise<void> {
    await this.dataTimeline();
    this.dataReady = true;
  }

  private async dataTimeline() {
    await timeline.activites({
      types: [{ id: "9", libelle: "FC" }],
      list: "ALL",
    });
  }

  private async setRecensementState(
    activite: string,
    state: ActiviteRecensementState
  ): Promise<void> {
    await TimelineService.recensement(activite, state);
    await this.dataTimeline();
  }

  private date(date: string, format = "DD/MM/YYYY"): string {
    return dayjs(date).format(format);
  }

  get prochaineActivite(): ProchaineActivite[] | null {
    return timeline.getProchaineActivite;
  }

  get activites(): Activite[] | null {
    return timeline.getActivites;
  }

  get typesActivite(): ElementKey[] | null {
    return data.getTypesActivite;
  }

  get TypeAlert(): any {
    return TypeAlert;
  }

  get context(): User | null {
    return login.getContext;
  }
}
