



































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Sidebar from "@/components/navigations/sidebar.vue";
import CardProchaineActivite from "@/components/card/cardProchaineActivite.vue";
import CardCalendrier from "@/components/card/cardCalendrier.vue";
import CardActivite from "@/components/card/cardActivite.vue";
import Alert, { TypeAlert } from "@/components/alert/alert.vue";
import panelActivite from "@/components/panel/panelActivite.vue";
import optionActivite from "@/components/option/optionActivite.vue";
import Modal from "@/components/modal/modal.vue";
import Cta from "@/components/buttons/Button.vue";
import Tag from "@/components/tags/Tag.vue";
import { activites, data, login, timeline } from "@/store";
import {
  Activite,
  ActiviteRecensementState,
  ElementKey,
  ProchaineActivite,
  User,
} from "@/models";
import TimelineService from "@/services/timeline.service";
import { found, select, isSameDay } from "@/helpers";
import { LocalStorageApp } from "@/utils/localstorage";
import dayjs, { Dayjs } from "dayjs";
import LoginService from "@/services/login.service";

@Component({
  methods: {
    found,
    select,
    isSameDay,
  },
  components: {
    Sidebar,
    CardProchaineActivite,
    Cta,
    CardCalendrier,
    CardActivite,
    Alert,
    panelActivite,
    optionActivite,
    Modal,
    Tag,
  },
})
export default class Home extends Vue {
  private dataReady = false;
  private panelActivite = false;
  private panelActiviteData: Activite | null = null;
  private optionActivite = false;
  private optionActiviteData: Activite | null = null;
  private filterModal = false;
  private nouveauteModal = false;
  private status = null;
  private filterTypes: ElementKey[] | null = null;
  private filterList: ElementKey[] = [
    { id: "ALL", libelle: "Tous" },
    { id: "NON_REPONDU", libelle: "Seulement où je dois répondre" },
    { id: "ENGAGE", libelle: "Seulement où je suis engagé" },
  ];
  private filterListSelected = "ALL";

  private paramSliderProchaineActivite = {
    align: "center",
    circular: true,
  };

  async mounted(): Promise<void> {
    // Check info nouveautés
    if (LocalStorageApp.getItem("pcp-timeline-v") !== "2.1.2") {
      this.nouveauteModal = true;
      LocalStorageApp.setItem("pcp-timeline-v", "2.1.2");
    }

    await data.typesActivites();
    if (this.typesActivite) {
      this.filterTypes = [...this.typesActivite.filter((t) => t.id !== "9")];
    }

    await this.dataTimeline();
    this.dataReady = true;

    this.status = await LoginService.status();
  }

  private async dataTimeline() {
    await timeline.prochaineActivite();
    await timeline.activites({
      types: this.filterTypes ?? [],
      list: this.filterListSelected,
    });
  }

  private confirmNouveaute(): void {
    this.nouveauteModal = false;
  }

  private async setRecensementState(
    activite: string,
    state: ActiviteRecensementState
  ): Promise<void> {
    await TimelineService.recensement(activite, state);
    await this.dataTimeline();
  }

  private async openSidebar(id: string): Promise<void> {
    this.panelActivite = true;
    if (await activites.activite(id)) {
      this.panelActiviteData = activites.getActivite;
    }
  }

  private async openOptionActivite(id: string): Promise<void> {
    this.optionActivite = true;
    if (await activites.activite(id)) {
      this.optionActiviteData = activites.getActivite;
    }
  }

  private closePanel(): void {
    this.panelActivite = false;
    this.panelActiviteData = null;
  }

  private closeOption(): void {
    this.optionActivite = false;
    this.optionActiviteData = null;
  }

  private openFilter(): void {
    this.filterModal = true;
  }

  private async confirmFilter(): Promise<void> {
    this.filterModal = false;
    await this.dataTimeline();
  }

  private async switchFilterType(selected: ElementKey): Promise<void> {
    if (this.filterTypes) {
      this.filterTypes = select(this.filterTypes, selected);
    }
  }

  private selectType(selected: string): void {
    this.filterListSelected = selected;
  }

  private date(date: string, format = "DD/MM/YYYY"): string {
    return dayjs(date).format(format);
  }

  private goFC() {
    this.$router.push({ name: "Recyclage" });
  }

  private goJOP() {
    window.open(
      "https://docs.google.com/spreadsheets/d/1PdeYyszps5m1tvnR_Wyg1iuz8SSGStlFvuLyeLEWSbw/edit?usp=sharing",
      "_blank"
    );
  }

  get prochaineActivite(): ProchaineActivite[] | null {
    return timeline.getProchaineActivite;
  }

  get activites(): Activite[] | null {
    return timeline.getActivites?.filter((a) => a.type?.id !== "9") ?? null;
  }

  get typesActivite(): ElementKey[] | null {
    return data.getTypesActivite
      ? data.getTypesActivite.filter((t) => t.id !== "9")
      : null;
  }

  get TypeAlert(): any {
    return TypeAlert;
  }

  get context(): User | null {
    return login.getContext;
  }

  get haveEarlyAccessForMe(): boolean {
    return this.context?.roles.includes("EARLY_V3") ?? false;
  }
}
